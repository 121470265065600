import React from "react";
import { graphql } from "gatsby";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import HeroImage from "../images/banking/hero.png";
import { GetStartedComponent, ProductBottomNavigation } from "./payments";
import { BusinessManagementIcon, FreeBusinessAccountIcon, OfflineSupportIcon, TotalControlIcon } from "../images/SpecialIcons";

const BankingPage = () => {
    return (
      <Layout pageTitle="Banking" type="mobile_transparent">
        <HeroContainer flip>
                <div className="hero-mask"></div>
                <div className="hero-content-wrapper">
                    <div className="hero-content">
                        <h1 className="hero-title font-grotesk">
                            <Trans>bankingpage.hero_title</Trans>
                        </h1>
                        <p className="hero-subtitle">
                            <Trans>bankingpage.hero_subtitle</Trans>
              </p>
                    </div>
                </div>
            </HeroContainer>
            <DetailsContainer>
                <div className="content">
                    <div className="details-section">
                        <div className="details">
                            <div className="details-text">
                                <div className="details-icon">
                                    <FreeBusinessAccountIcon />
                                </div>
                                <div className="details-title">
                                    <Trans>bankingpage.details_1_title</Trans>
                                </div>
                                <div className="details-description">
                                    <Trans>bankingpage.details_1_description</Trans>
                                </div>
                            </div>

                            <StaticImage
                                alt="..."
                                src="../images/banking/banking-2.png"
                  className="details-image grow"
                            />
                        </div>
                        <div className="details swap">
                            <div className="details-text">
                                <div className="details-icon">
                                    <TotalControlIcon />
                                </div>
                                <div className="details-title">
                                    <Trans>bankingpage.details_2_title</Trans>
                                </div>
                                <div className="details-description">
                                    <Trans>bankingpage.details_2_description</Trans>
                                </div>
                            </div>

                            <StaticImage
                                alt="..."
                                src="../images/banking/banking-3.png"
                  className="details-image grow"
                            />
                        </div>
                    </div>
                </div>
            </DetailsContainer>
            <GetStartedDetailsContainer>
                <div className="content">
                    <div className="text-container">
                        <h2 className="title">
                            <Trans>bankingpage.get_started_title</Trans>
                        </h2>
                        <p className="description">
                            <Trans>bankingpage.get_started_description</Trans>
                        </p>
                    </div>
                    <StaticImage
                        alt="..."
                        src="../images/banking/banking-1.png"
                        className="image-container"
            />
                </div>
            </GetStartedDetailsContainer>
            <DetailsContainer>
                <div className="content">
                    <div className="details-section">
                        <div className="details">
                            <div className="details-text">
                                <div className="details-icon">
                                    <OfflineSupportIcon />
                                </div>
                                <div className="details-title">
                                    <Trans>bankingpage.details_3_title</Trans>
                                </div>
                                <div className="details-description">
                                    <Trans>bankingpage.details_3_description</Trans>
                                </div>
                            </div>

                            <StaticImage
                                alt="..."
                                src="../images/banking/banking-4.png"
                  className="details-image grow"
                            />
              </div>
            </div>
          </div>
            </DetailsContainer>
        <ProductBottomNavigation
          prev="business_management"
          prevTo="/business-management"
          next="payments"
          to="/payments"
        />
        <GetStartedComponent title="bankingpage.bottom_get_started_title" cta="bankingpage.bottom_get_started_cta" to="mailto:partner@moniepoint.com" />
        </Layout>
    );
};

export default BankingPage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background: url(${HeroImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 497px;
  position: relative;

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 100vh;
    max-height: 730px;
    background-size: cover;
    background-position: 75%;

    .hero-mask {
      background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0) 0%,
        rgba(29, 29, 29, 0.7) 100%
      );
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }
      .hero-subtitle {
        font-size: 17px;
        line-height: 21px;
      }
    }
  }
`;

const DetailsContainer = styled.section`
  background-color: ${(props) => props.backgroundColor || "white"};


  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 151px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }

    .details-section {
      display: flex;
      flex-direction: column;
      gap: 84px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 64px;

      &.swap {
        .details-text {
          order: 2;
        }
      }

      .details-text {
        width: fit-content;
      }

      .details-icon {
        display: none;
        margin-bottom: 16px;
        svg{
          height: 64px;
          width: 64px;
        }
      }

      .details-title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 24px;
        max-width: 487px;
      }
      .details-description {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0px;
        max-width: 487px;
      }
      .details-image {
        border-radius: 8px;
        height: 491px;
        max-width: 484px;
        width: 100%;

        &.special {
          height: 100%;
          top: 80px;
          border-radius: 8px 8px 0 0;
          display: block;
          width: 524px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;
      .details-section {
        gap: 48px;
      }
      .details {
        flex-direction: column;
        gap: 14px;

        .details-text {
          order: 2;
        }

        .details-icon{
          display: block;
        }

        .details-title {
          font-size: 28px;
          line-height: 33.04px;
          margin-bottom: 8px;
        }

        .details-description {
          font-size: 16px;
          line-height: 25.6px;
        }

        .details-image {
          border-radius: 8px;
          height: 330px;
          max-width: 100%;
          display: none;

          &.special {
            top: 0;
            border-radius:0 0;
            width: 340px;
            margin-bottom: 48px;
          }
        }
      }
    }
  }
`;

const GetStartedDetailsContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 48px 151px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      padding: 48px 60px 0;
    }

    .text-container {
      .title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 761px;
        width: 100%;
        margin-bottom: 24px;
      }

      .description {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 761px;
        width: 100%;
        margin-bottom: 0px;
      }
    }

    .image-container {
      height: auto;
      margin-top: 28px;
      max-width: 507px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px 56px;
      gap:48px;

      .text-container {
        order: 2;
        .title {
          text-align: left;
          margin-bottom: 8px;
          font-size: 28px;
          line-height: 33.04px;
        }
        .description {
          text-align: left;
          font-size: 16px;
          line-height: 25.6px;
        }
      }
      .image-container {
      margin-top: 0px;
      }

      .btn.btn-primary {
        align-self: flex-start;
      }
    }
  }
`;
