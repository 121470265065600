import React from "react";
import { graphql } from "gatsby";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import PaymentsBg from "../images/payments/hero.png";
import SpecialBg from "../images/special-bg.png";
import { BankTransferIcon, PaymentOptionsIcon, WebPaymentsIcon } from "../images/SpecialIcons";
import { ArrowLeftIcon, ArrowRightIcon } from "../images/Icons";

const PaymentsPage = () => {
  return (
    <Layout pageTitle="Payment" type="mobile_transparent">
      <HeroContainer flip>
        <div className="hero-mask"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1 className="hero-title font-grotesk">
              <Trans>paymentspage.hero_title</Trans>
            </h1>
            <p className="hero-subtitle">
              <Trans>paymentspage.hero_subtitle</Trans>
            </p>
          </div>
        </div>
      </HeroContainer>
      <DetailsContainer>
        <div className="content">
          <div className="details-section">
            <div className="details">
              <div className="details-text">
                <div className="details-icon">
                  <WebPaymentsIcon />
                </div>

                <div className="details-title">
                  <span><Trans>paymentspage.details_1_title_a</Trans></span><span className="blue"><Trans>paymentspage.details_1_title_b</Trans></span><span><Trans>paymentspage.details_1_title_c</Trans></span>
                </div>
                <div className="details-description">
                  <Trans>paymentspage.details_1_description</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../images/payments/payments-1.png"
                className="details-image grow"
              />
            </div>
          </div>
        </div>
      </DetailsContainer>
      <DetailsContainer backgroundColor="rgba(3, 87, 238, 0.03)" className="special">
        <div className="content">
          <div className="details-section">
            <div className="details">
              <div className="details-text">
                <div className="details-title">
                  <span><Trans>paymentspage.details_2_title_a</Trans></span> <span className="blue"><Trans>paymentspage.details_2_title_b</Trans></span><span><Trans>paymentspage.details_2_title_c</Trans></span>
                </div>
                <div className="details-description">
                  <Trans>paymentspage.details_2_description</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../images/payments/payments-4.png"
                className="details-image special"
              />
            </div>
          </div>
        </div>
      </DetailsContainer>
      <DetailsContainer>
        <div className="content">
          <div className="details-section">
            <div className="details swap">
              <div className="details-text">
                <div className="details-icon">
                  <BankTransferIcon />
                </div>
                <div className="details-title">
                  <Trans>paymentspage.details_3_title</Trans>
                </div>
                <div className="details-description">
                  <Trans>paymentspage.details_3_description</Trans>
                </div>
              </div>

              <StaticImage
                alt="..."
                src="../images/payments/payments-2.png"
                className="details-image grow"
              />
            </div>
          </div>
        </div>
      </DetailsContainer>
      <ProductBottomNavigation
        prev="banking"
        prevTo="/banking"
        next="credit"
        to="/credit"
      />
      <GetStartedComponent title="paymentspage.get_started_title" cta="paymentspage.get_started_cta" to="mailto:partner@moniepoint.com" />
    </Layout>
  );
};

export default PaymentsPage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const GetStartedComponent = ({ title = 'getstarted.title_2', cta = 'get_started', to = "", linkOptions }) => (
  <GetStartedContainer>
    <div className="content">
      <div className="get-started-section">
        <h2 className="title"><Trans>{title}</Trans></h2>
        <Link to={to} {...linkOptions} className="btn btn-primary ">
          <Trans>{cta}</Trans>
        </Link>
      </div>
    </div>
  </GetStartedContainer>
)

export const ProductBottomNavigation = ({ next, to, prev, prevTo }) => (
  <BottomNaviagtorContainer>
    <div className="content">
      <div className="button-content">
        <Link to={prevTo} className="left-button">
          <div className="button-icon">
            <ArrowLeftIcon />
          </div>
          <span>
            <Trans>{prev}</Trans>
          </span>
        </Link>

        <Link to={to} className="right-button">
          <span>
            <Trans>{next}</Trans>
          </span>
          <div className="button-icon">
            <ArrowRightIcon />
          </div>
        </Link>
      </div>
    </div>
  </BottomNaviagtorContainer>
);

const HeroContainer = styled.section`
  background: url(${PaymentsBg});
  background-size: cover;
  background-position: center;
  min-height: 497px;
  position: relative;

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 100vh;
    max-height: 730px;
    background-position: 80% bottom;

    .hero-mask {
      background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0) 0%,
        rgba(29, 29, 29, 0.7) 100%
      );
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .hero-title {
        font-size: 40px;
        line-height: 43.8px;
      }
      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
        
      }
    }
  }
`;

const DetailsContainer = styled.section`
  background-color: ${(props) => props.backgroundColor || "white"};

  
  &.special {
   .content {
    padding: 32px 151px 0;
    @media only screen and (max-width: 1024px) {
      padding: 32px 60px 0;
    }
    @media only screen and (max-width: 768px) {
      padding: 32px 20px 48px;
    }
    .details {
      gap:0px;
    }
   }
  }

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 151px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }

    .details-section {
      display: flex;
      flex-direction: column;
      gap: 84px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 64px;

      &.swap {
        .details-text {
          order: 2;
        }
      }

      .details-text {
        width: fit-content;
      }

      .details-icon {
        display: none;
        margin-bottom: 16px;
        svg{
          height: 64px;
          width: 64px;
        }
      }

      .details-title, .details-title>span  {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 24px;
        max-width: 487px;

        .blue {
          color: rgba(3, 97, 240, 1);
        }
      }
      .details-description {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0px;
        max-width: 487px;
      }
      .details-image {
        border-radius: 8px;
        height: 491px;
        max-width: 484px;
        width: 100%;

        &.special {
          height: 100%;
          border-radius: 8px 8px 0 0;
          display: block;
          width: 524px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;
      .details-section {
        gap: 64px;
      }
      .details {
        flex-direction: column;
        gap: 14px;

        .details-text {
          order: 2;
        }

        .details-icon{
          display: block;
        }

        .details-title, .details-title>span  {
          font-size: 28px;
          line-height: 38px;
          margin-bottom: 8px;
        }

        .details-description {
          font-size: 16px;
          line-height: 28px;
        }

        .details-image {
          border-radius: 8px;
          height: 330px;
          max-width: 100%;
          display: none;

          &.special {
            top: 0;
            border-radius:0 0;
            width: 340px;
            margin-bottom: 48px;
          }
        }
      }
    }
  }
`;

const GetStartedContainer = styled.section`
    background: rgba(3, 87, 238, 0.03) url(${SpecialBg});
    background-size: contain;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 59px 151px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 59px 60px;
    }

    .get-started-section {
      display: flex;
flex-direction: column;
align-items: center;

.title {
  font-family: 'Founders Grotesk';
font-size: 32px;
font-weight: 500;
line-height: 45px;
letter-spacing: 0em;
text-align: center;
max-width: 881px;
margin-bottom: 32px;
}

.get-started-button {
  margin: 0 auto;
  padding: 22.5px 74.5px;
}
    }


  
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;
    
      .get-started-section{
        .title {
font-size: 24px;
line-height: 28px;
letter-spacing: -0.005em;

        }

        .get-started-button {
          padding: 18.5px 24px;
        }
      }
    }
  }
`;


const BottomNaviagtorContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 32px 150px 64px;
    @media only screen and (max-width: 1024px) {
      padding: 32px 60px 64px;
    }

    .button-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-button,
      .right-button {
        display: flex;
        align-items: center;
        gap: 24px;
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-decoration: none;
        text-transform: capitalize;
        color: black;

        .button-icon {
          padding: 10px;
          border-radius: 8px;
          height: 44px;
          width: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(3, 87, 238, 0.1);
        }
      }

      .left-button {
        color: rgba(0, 0, 0, 0.7);
        text-align: left;
      }
      .right-button {
        text-align: right;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 0px 20px 48px;

      .button-content {
        .left-button,
        .right-button {
          gap: 16px;
          font-size: 16px;
          line-height: 18.88px;

          .button-icon {
            height: 36px;
            width: 36px;
          }
        }
      }
    }
  }
`;

